.dropdown-menu{
	z-index: 1000;
	display: flex;
	flex-direction: column;
	float: left;
	min-width: 10rem;
	margin: .125rem 0 0;
	font-size: 1rem;
	color: RGB(var(--main-accent-text-color));
	text-align: left;
	list-style: none;
	background-color: RGB(var(--main-accent-color));
	background-clip: padding-box;
	border: 1px solid RGB(var(--main-accent-color-dark));
	border-radius: .25rem;
	box-shadow: 3px 5px 3px 2px rgba(0,0,0,0.5);

	> a{
		text-decoration: none;
		color: RGB(var(--main-accent-text-color));
	}
	
	> .dropdown-item {
		display: block;
		width: 100%;
		padding: .75rem 1.5rem;
		clear: both;
		font-weight: 400;
		text-align: inherit;
		white-space: nowrap;
		background-color: transparent;
		cursor: pointer;
		font-weight: bold;
		transition: background-color ease-in-out .4s;
		
		
		&.active, &:hover{
			background-color: RGB(var(--main-accent-color-dark));
		}
		
		
		&:not(:last-child){
			border-bottom : 1px solid RGB(var(--main-accent-color-dark));
		}
	}
	
	
}