@keyframes swing-in-top-fwd {
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-bwd {
  0% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
  100% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }
}

.dropdown-wrapper{
	position: relative;
	
	> .dropdown-trigger{
		cursor: pointer;
	}
	
	> .dropdown-content{
		position: absolute;
		z-index: 1000;
		float: left;
		width: max-content;
		will-change: transform;
		animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
		&.fading{
			animation: swing-in-top-bwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
		}
		
	}
}