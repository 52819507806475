.form-title{
	color: RGB(var(--title-color));
    font-size: 1.8em;
    letter-spacing: .1px;
}

.form-holder{
	position: relative;
}

.form-item{
	color: RGB(var(--title-color));
    font-size: 14px;
    line-height: 1.5;
	padding-bottom : 8px;
	position: relative;
	
	&:after, &:before {
		display: table;
		content: "";
	}
	
	> .form-item-label{
	    display: block;
		margin: 0;
		padding: 0 0 8px;
		line-height: 1.5;
		white-space: normal;
		text-align: left;
		
		> label{
			color: RGB(var(--title-color));
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 0;
		}
	}
	
	> .form-item-error{
		color: rgb(237, 37, 61);
		clear: both;
		min-height: 22px;
		margin-top: -2px;
		font-size: 14px;
		line-height: 1.5;
	}
	
	> .form-item-field{
		position: relative;
	
		> .form-item-wrapper{
			box-sizing: border-box;
			margin: 0;
			padding: 0;
			color: RGB(var(--title-color));
			font-size: 14px;
			line-height: 1.5;
			list-style: none;
			position: relative;
			width: 100%;
			text-align: start;
			display: inline-block;
			
			> .input-wrapper{
				display: flex;
				
				> .checkbox-label, > .radio-label{				
					color: RGB(var(--title-color));
					font-size: 16px;
					font-weight: bold;
					margin-bottom: 0;
					margin-left: 5px;
					cursor: pointer;
				}
				
				> input[type=checkbox]{
					border-radius: 3px;
				
					&:checked {
						&:after {
							content: '\2714';
							font-size: 14px;
							color: RGB(var(--main-accent-color));
							top: 0px;
							left: 4px;
						}
					}
				}

				> input[type=radio]{
					border-radius: 50%;
					
					&:checked {
						&:after {
							content: ' ';
							height: 11px;
							width: 11px;
							background-color: RGB(var(--main-accent-color));
							display: block;
							border-radius: 50%;
							top: 4px;
							left: 4px;
						}
					}
				}

				> input[type=checkbox], > input[type=radio]{
					appearance: none;
					background-color: RGBA(var(--input-field-bg), .7);
					border: 1px solid RGBA(var(--input-field-border), .3);
					padding: 9px;
					display: inline-block;
					position: relative;
					outline: none;
					height: 23px;
					width: 23px;
					cursor: pointer;
					
					&:required:invalid{
						border-color: RGBA(237, 37, 61, .5);
					}
					
					&:active, &:checked:active, &:hover {
						background-color: RGBA(var(--input-field-bg), .9);
						&:required:invalid{
							border-color: RGBA(237, 37, 61, .8);
						}
					}

					&:checked {
						background-color: RGBA(var(--input-field-bg), .9);
						border-color: RGB(var(--main-accent-color));
						color: RGB(var(--main-accent-color));
						
						&:after {
							position: absolute;
						}
					}
				}
			}
			
			> .form-item-prefix, > .form-item-suffix{
			    position: absolute;
				top: 50%;
				z-index: 2;
				color: RGB(var(--title-color));
				line-height: 0;
				transform: translateY(-50%);
			}
			
			> .form-item-prefix{
				left: 12px;
			}
			
			> .form-item-suffix{
				right: 12px;
			}
			
			> select{
				border-radius: 15px 15px 0 0;
				> option{
					background-color: RGB(var(--input-field-bg));
					border-color: RGBA(var(--input-field-border), .3);
					border-style: solid;
				}
				
				> option:hover,
				> option:focus,
				> option:active,
				> option:checked{
					color: #000;
					font-weight: bold;
					background-color: RGB(var(--main-accent-color));
				} 
			}
			
			/*input::selection, textarea::selection, select::selection {
				background-color: transparent;
			}*/
			
			> span.input{
				display: flex;
				align-content: center;
				justify-items: center;
				line-height: 40px;
				overflow: hidden;
			}
			
			> span.input, > textarea, > input:not([type=checkbox]):not([type=radio]), > select{
				box-shadow: none;
				border: 0px solid RGBA(var(--input-field-border), .3);
				background-color: RGBA(var(--input-field-bg), .7);
				height: 40px;
				color: RGB(var(--title-color));
				padding: 0 30px;
				position: relative;
				text-align: inherit;
				min-height: 100%;
				width: 100%;
				outline : none;
				background-size: 13px;
				background-repeat: no-repeat;
				background-position: calc(100% - 43px) center;
				
				&:required:invalid{
					background-image: url(../../Assets/required.png);
					border-color: RGBA(237, 37, 61, .5);
				}
				
				&:focus, &:hover{
					outline : none;
					border-color: RGBA(var(--input-field-border), .5);
					background-color: RGBA(var(--input-field-bg), .9);
					
					&:required:invalid{
						border-color: RGBA(255, 0, 0, .8);
					}
				}
			}
			
			> textarea{
				border-radius: 15px;
				resize: none;
				padding: 10px 30px;
				height: 120px;
			}
			
			> span.input, > input:not([type=checkbox]):not([type=radio]){
				border-radius: 30px;
			}
		
			textarea:disabled, input:disabled, select:disabled {
				cursor: not-allowed;
				background-color: #ccc !important;
			}
		
		}
	}

	
	select, textarea, input[type="text"], input[type="password"],
	input[type="datetime"], input[type="datetime-local"],
	input[type="date"], input[type="month"], input[type="time"],
	input[type="week"], input[type="number"], input[type="email"],
	input[type="url"]{
	
		font-size: 16px !important;
		
		/*@media screen and (device-aspect-ratio: 2/3) {
			font-size: 16px !important;
		}
		
		@media screen and (device-aspect-ratio: 40/71) {
			font-size: 16px !important;
		}
		
		@media screen and (device-aspect-ratio: 9/16) {
			font-size: 16px !important;
		}
		
		@media screen and (device-aspect-ratio: 375/667) {
			font-size: 16px !important;
		}*/
	}
}