.hero-wrapper{
	position: relative;
	top: 0;
	left: 0;
	min-height: 100vh;
	width: 100vw;
	
	@keyframes color-change{
		0%{background-color:rgba(0, 0, 0, var(--hero-dark-alpha))}
		100%{background-color:rgba(var(--main-accent-color), .7)}
	}

	> .hero-image, > .hero-gradient{
		position: absolute;
	}
	
	> .hero-image, > .hero-gradient, > .hero-content{
		top: 0;
		left: 0;
		min-height: 100vh;
		width: 100vw;
		height: 100%;
	}

	> .hero-image{
		background-size: cover;
		z-index: 1;
		background-attachment: fixed;
		@media (max-width : 768px){
			background-position : center;
		}
		
	}

	> .hero-gradient{
		z-index: 2;
		animation: color-change 5s linear infinite alternate both;
	}

	> .hero-image + .hero-gradient{
		mix-blend-mode: multiply;
	}

	> .hero-content{
		position: relative;
		z-index: 3;
		display: flex;
		align-items: center;
	}
	
}