.hero-card{
	align-items: stretch;
    background: RGBA(var(--hero-card-color), var(--hero-card-alpha));
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
    display: flex;
    flex-direction: column;
    margin-top: 2.9rem;
    padding: 6.4rem 7.1rem;
    width: 100%;
	
	
	@media (max-width: 768px){
		padding: 2.1rem;
		margin: 2.1rem auto .7rem;
	}
	
	
	> .hero-title{
		@media (max-width: 767px){padding: 0;}
	    align-items: baseline;
		display: flex;
		justify-content: space-between;
		padding-left: 1.8rem;
		width: 100%;
	}
	
	
	> .hero-body{
		width: 100%;
		position: relative;
	}
	
}