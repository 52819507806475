.App{
    height: 100%;
	background-color: RGB(var(--bg-color));
	display: block;
	color: RGB(var(--text-color));
}

.app-container{
	display: flex;
    flex-direction: column;
	height: 100%;
	will-change: padding;
	padding-left: 0;
    /*transition: padding .2s ease-in-out;*/
}

.overlay{
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, .15);
	top: 0;
	left: 0;
	display: block;
	z-index: 9999;
	position: fixed;
}

*, :after, :before {
	box-sizing: border-box;
}

@media screen and (min-width : 769px){
	.app-container.logged{
		padding-left: 18.57143rem;
	}
}